import repository from "~~/repository";
export const menuStore = defineStore('menu', {
    namespaced: true,
    state: () => ({
        menuInfor: {
            isLoading: true,
            userName: '',
            avarta: '',
            menuClassToggle: 'in',
            menuItemWidth: 'auto',
            showModal: false,
            menuList: [
                {
                    menuLink: '/',
                    menuName: 'Trang Chủ',
                },
                {
                    menuLink: '/vocabulary',
                    menuName: 'Học Từ Vựng',
                },
                {
                    menuLink: '/grammar',
                    menuName: 'Học Ngữ Pháp',
                },
                {
                    menuLink: '/listening',
                    menuName: 'Học Nghe',
                },
                {
                    menuLink: '/writing',
                    menuName: 'Học Viết',
                },
                {
                    menuLink: '/reading',
                    menuName: 'Đọc Hiểu',
                },
                {
                    menuLink: '/pronunciation',
                    menuName: 'Học Phát Âm',
                },
                {
                    menuLink: '/dictionary',
                    menuName: 'Từ Điển E+',
                },
                {
                    menuLink: '/conversation',
                    menuName: 'Hội Thoại',
                },
                {
                    menuLink: '/discuss',
                    menuName: 'Hỏi Đáp',
                },
            ],
        },
    }),
    actions: {
        updateMenuInfor(menuInfor) {
            var _this = this;
            Object.keys(menuInfor).forEach(function (key) {
                _this.menuInfor[key] = menuInfor[key];
            })
        },
        cancelMission() {
            var { $appStates, $appMethods, $callHelpers } = useNuxtApp();
            var messageCode = 'C009';
            var messageParams = [];
            var currentMission = $appStates.app.appInfor.doingMission;
            var tryTimeCount = currentMission.missionTryTimes - currentMission.missionTryTimesCount;
            if (tryTimeCount == 0) {
                messageCode = 'C010';
            }
            $appMethods.showDialog(1, messageCode, function () {
                messageParams = [
                    messageCode,
                    [
                        tryTimeCount,
                    ]
                ];
                $appStates.dialogPopup.dialogPopupInfor.dialogMessage = $callHelpers('getMessage', messageParams);
                $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                    var payload = {
                        mission_id: currentMission.missionId,
                    };
                    repository.cancelMission(payload)
                        .then((res) => {
                            // const { data } = res;
                            if (res.status === 200) {
                                var data = res.data.data;
                                if (data[1][0].missionTryTimes == data[1][0].missionTryTimesCount) {
                                    $appMethods.showDialog(3, 'W006', function () {
                                        messageParams = [
                                            'W006',
                                            [
                                                data[1][0].failedMissionExp * data[1][0].difficultLevel,
                                                data[1][0].failedMissionCtp * data[1][0].difficultLevel,
                                            ]
                                        ];
                                        $appStates.dialogPopup.dialogPopupInfor.dialogMessage = $callHelpers('getMessage',messageParams);
                                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons.pop();
                                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                                            $appMethods.getAppState();
                                            $appStates.app.appInfor.isReloadContent = true;
                                            $appMethods.hideDialog();
                                        }
                                    })
                                } else {
                                    $appMethods.showDialog(3, 'W005', function () {
                                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons.pop();
                                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                                            $appMethods.getAppState();
                                            $appStates.app.appInfor.isReloadContent = true;
                                            $appMethods.hideDialog();
                                        }
                                    })
                                }
                                // console.log(state);
                                return res;
                            }
                        });

                }
            });
        },
    }
});