<!-- <script setup>
// const post = await fetch(`https://eplus365.com/api/common/app-meta-data`,
//     {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//     }
// );
// const meta = await post.json();
definePageMeta({
    // key: '_bar',
    // or a method
    key: route => route.meta.rootPath
});
</script> -->
<template>
    <!-- <Head>
		<Title>{{ meta.title }}</Title>
		<Meta name="description" :content="meta.title" />
	</Head> -->
    <div class="body-content" :style="{ 'min-height': 'calc(100vh - ' + appInfor.footerHeight + 'px)' }">
        <div class="col-xs-12 web-panel header-content">
            <div class="row top-header">
                <div class="logo-box">
                    <div>
                        <span class="logo-box-text">WE ARE ONE</span>
                        <span class="logo-box-text">
                            <span class="logo-icon">
                                <font-awesome-icon icon="graduation-cap" class="logo-icon" />
                            </span>
                            WITH
                        </span>
                    </div>
                    <div class="div-link" href="/">
                        <NuxtLink class="logo-box-text1" to="/">{{ headerInfor.webName }}</NuxtLink>
                        <!-- <span class="logo-box-text1">{{headerInfor.webName}}</span> -->
                    </div>
                </div>
                <div class="slogan-box">
                    <!-- <span v-if="appInfor.isLoading" class="text-slogan">
                        <ContentLoader viewBox="0 0 3000 130" style="width:50%;height:100%" :speed="3" primaryColor="#666" condaryColor="#fff">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                        </ContentLoader>
                    </span> -->
                    <span class="text-slogan"> {{ headerInfor.slogan }} </span>
                </div>
            </div>
            <Menu />
        </div>
        <NuxtPage page-key="static" />
        <ClientOnly>
            <v-loading :active="appInfor.isSwitching" :is-full-page="true" :opacity="0.2">
                <slot name="default">
                    <font-awesome-icon :icon="['fas', 'compact-disc']" spin size="5x" style="color: #777" />
                </slot>
            </v-loading>
        </ClientOnly>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import Menu from '@/components/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { headerStore } from "./store";

/**
 * Define main layout
 */
const Header = {
    name: "Header",
    // template: template,
    // key: '_bar',
    components: {
        // Menu,
    },
    beforeCreate() {
        // if (!store.hasModule('header')) {
        //     store.registerModule('header', headerStore);
        // }
    },
    computed: {
        ...mapState(headerStore, ["headerInfor"]),
    },
    mounted() {},
    methods: {
        ...mapActions(headerStore, ["updateHeaderInfor"]),
    },
};
export default defineNuxtComponent(Header);
</script>
<style lang="scss">
@import "./style.scss";
</style>
