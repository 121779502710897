<template>
    <vue-final-modal v-model="dialogPopupInfor.showDialog" :transition="dialogPopupInfor.modalTransition">
        <div class="modal-dialog popup-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header login-modal" :class="dialogPopupInfor.dialogColor">
                    <button v-if="dialogPopupInfor.isShowCancelButon" class="close" data-dismiss="modal" type="button" @click="dialogPopupInfor.showDialog = false">×</button>
                    <div class="modal-icon login-modal">
                        <font-awesome-icon :icon="dialogPopupInfor.dialogIcon" size="5x" :[dialogPopupInfor.dialogTransition]="dialogPopupInfor.dialogTransition" style="--fa-animation-duration: 2s" />
                    </div>
                    <div class="modal-title login-modal">
                        <span>{{ dialogPopupInfor.dialogTitle }}</span>
                    </div>
                </div>
                <div class="modal-body">
                    <span v-if="dialogPopupInfor.dialogMessage != ''" class="login-message" v-html="dialogPopupInfor.dialogMessage"></span>
                </div>
                <div class="modal-footer">
                    <table class="table table-button">
                        <tbody>
                            <tr>
                                <td class="no-padding" :class="dialogPopupInfor.dialogButtons.length==1?'single-btn':''" v-for="(dialogButton, index) in dialogPopupInfor.dialogButtons">
                                    <button class="btn btn-sm" :class="dialogButton.buttonClass" :ref="'dialogButton' + index" type="button" @click="dialogButton.buttonAction">{{ dialogButton.buttonTitle }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store.js";
import { dialogPopupStore } from "./store";

/**
 * Define main layout
 */
const DialogPopup = {
    name: "DialogPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("dialogPopup")) {
        // 	store.registerModule("dialogPopup", dialogPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(dialogPopupStore, ["dialogPopupInfor"]),
    },
    mounted() {
        // console.log(this.callHelpers('getMessage','E003'));
        // this.getDialogPopupInfor();
		// this.$watch(
        //     () => ({
        //         // showList: this.showList,
        //         isDialogOpen: this.dialogPopupInfor.showDialog,
        //     }),
        //     (newValue) => {
        //         if(newValue.isDialogOpen){
        //             var _this = this;
        //             setTimeout(() => {
        //                 _this.$refs.dialogButton0[0].focus();
        //             }, 500);
        //         }
        //     },
        //     {
        //         immediate: true,
        //     }
        // );
    },
    methods: {
        ...mapActions(dialogPopupStore, ["showDialog", "updateDialogPopupInfor"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
    },
};
export default defineNuxtComponent(DialogPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
