<template>
    <div class="col-xs-12 bottom-content" ref="bottomContent">
        <div class="row">
            <div class="col-lg-3 col-sm-4 bottom-left">
                <span>Eplus</span>
                <span>©Eplus 2018 , all rights reserved</span>
                <span>Phiên bản : {{ footerInfor.version }} - {{ footerInfor.versionDate }}</span>
                <span
                    >{{ footerInfor.footerSlogan }}
                    <font-awesome-icon icon="hand-peace" />
                </span>
            </div>
            <div class="col-lg-7 col-sm-5 bottom-right">
                <span class="block">Contact</span>
                <div v-for="contact in footerInfor.contacts" class="inline-block contacts-block">
                    <a target="_blank" :href="contact.contactLink">
                        <font-awesome-icon :icon="contact.contactIcon" />
                        {{ contact.contactName }}
                    </a>
                </div>
                <div class="block"></div>
                <div class="inline-block">
                    <a :href="'tel:' + footerInfor.tel"><font-awesome-icon icon="phone-volume" /> {{ footerInfor.tel }}</a>
                </div>
                <div class="inline-block">
                    <a :href="'mailto:' + footerInfor.email"><font-awesome-icon icon="envelope" /> {{ footerInfor.email }}</a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-3 bottom-right">
                <span class="block">Infor</span>
                <div class="block">
                    <span>Birthday : {{ footerInfor.birthday }} <font-awesome-icon icon="cake-candles" /></span>
                </div>
                <div class="block">
                    <span>Epluser : {{ footerInfor.epluser }} <font-awesome-icon icon="users" /></span>
                </div>
                <div class="block">
                    <a :href="footerInfor.privacyCookiesLink">Privacy & Cookies <font-awesome-icon icon="gavel" flip="horizontal" /></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { footerStore } from "./store";

/**
 * Define main layout
 */
const Footer = {
    name: "Footer",
    // template: template,
    components: {
        // MainMenu
    },
    beforeCreate() {
        // if (!store.hasModule('footer')) {
        //     store.registerModule('footer', footerStore);
        // }
    },
    computed: {
        ...mapState(footerStore, ["footerInfor"]),
    },
    mounted() {},
    methods: {
        ...mapActions(footerStore, ["updateFooterInfor"]),
        updateResizedScreen() {
            this.updateAppInfor({
                footerHeight: this.$refs.bottomContent.clientHeight < 82 ? 82 : this.$refs.bottomContent.clientHeight,
            });
        },
    },
};
export default defineNuxtComponent(Footer);
</script>
<style lang="scss">
@import "./style.scss";
@import "scss/common/screencontroller.scss";
</style>
