<template>
    <vue-final-modal v-model="selectVocabularyPopupInfor.showModal" :transition="selectVocabularyPopupInfor.modalTransition" :clickToClose="false">
        <div class="modal-dialog select-vocabulary" ref="abc">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">LỰA CHỌN TỪ VỰNG</h5>
                    <!-- <button class="close" data-dismiss="modal" type="button" @click="selectVocabularyPopupInfor.showModal = false">×</button> -->
                </div>
                <div class="modal-body no-padding">
                    <div class="col-xs-12 dialog-padding no-fixed search-panel" data-target="#select-condition" data-toggle="collapse">
                        <h5>
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                            Điều Kiện Tìm Kiếm
                        </h5>
                    </div>
                    <div class="col-xs-12 no-padding margin-top collapse in" min-width="1024px" id="select-condition">
                        <div class="row no-margin">
                            <div class="col-xs-12 no-padding">
                                <div class="col-md-6 col-xs-12 dialog-padding">
                                    <div class="form-group">
                                        <label>Tên Từ Vựng</label>
                                        <div class="input-group" v-tooltip.tooltip="selectVocabularyPopupInfor.vocabularyName.isError ? selectVocabularyPopupInfor.vocabularyName.errorMessage : ''">
                                            <input class="form-control input-sm" :class="selectVocabularyPopupInfor.vocabularyName.isError ? 'input-error' : ''" ref="vocabularyName" type="type" v-model="selectVocabularyPopupInfor.vocabularyName.value" @keyup.enter="getVocabularyList()" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12 dialog-padding">
                                    <div class="form-group">
                                        <label>Nghĩa Từ Vựng</label>
                                        <div class="input-group" v-tooltip.tooltip="selectVocabularyPopupInfor.vocabularyMean.isError ? selectVocabularyPopupInfor.vocabularyMean.errorMessage : ''">
                                            <input class="form-control input-sm" :class="selectVocabularyPopupInfor.vocabularyMean.isError ? 'input-error' : ''" id="account_nm" type="type" v-model="selectVocabularyPopupInfor.vocabularyMean.value" @keyup.enter="getVocabularyList()" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 dialog-padding no-fixed result-panel">
                        <h5>
                            <font-awesome-icon icon="list-ul" />
                            Kết Quả Tìm Kiếm
                        </h5>
                        <div v-if="!appInfor.isMobile" class="table-fixed-width no-padding-left" min-width="1500px">
                            <table class="table table-hover table-bordered table-preview table-custom" style="min-width: 900px">
                                <thead>
                                    <tr>
                                        <th width="40px">Chọn</th>
                                        <th class="hidden"></th>
                                        <th width="100px">Tên Từ Vựng</th>
                                        <th width="110px">Chuyên Ngành</th>
                                        <th width="120px">Lĩnh Vực</th>
                                        <th width="120px">Loại Từ Vựng</th>
                                        <th width="120px">Phiên Âm</th>
                                        <th>Nghĩa</th>
                                        <th width="90px">Hình Ảnh</th>
                                        <th width="90px">Âm Thanh</th>
                                        <th class="hidden"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="selectVocabularyPopupInfor.searchedList.length > 0">
                                        <tr v-for="(selectedItem, index) in selectVocabularyPopupInfor.searchedList">
                                            <td class="no-padding">
                                                <button class="btn btn-sm btn-primary full-width btn-add" type="button" @click="selectVocabulary(selectedItem, index)"><font-awesome-icon icon="arrow-down" /></button>
                                            </td>
                                            <td class="refer-item text-left" refer_id="vocabulary_nm">{{ selectedItem.vocabularyName }}</td>
                                            <td>{{ selectedItem.vocabularySpecializedName }}</td>
                                            <td>{{ selectedItem.vocabularyFieldName }}</td>
                                            <td>{{ selectedItem.vocabularyDivName }}</td>
                                            <td class="refer-item" refer_id="spelling">{{ selectedItem.vocabularySpelling }}</td>
                                            <td class="refer-item text-left" refer_id="mean">{{ selectedItem.vocabularyMean }}</td>
                                            <td class="refer-item" refer_id="image"></td>
                                            <td class="refer-item" refer_id="audio"><audio class="sound1" :src="appInfor.domain + selectedItem.vocabularyAudio"></audio><a type="button" class="preview-audio">Nghe thử</a></td>
                                        </tr>
                                    </template>
                                    <template v-else-if="selectVocabularyPopupInfor.paging.totalRecord == 0">
                                        <tr>
                                            <td colspan="11">Không có dữ liệu nào khớp với điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="11">Xin nhập điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="table-fixed-width no-padding-left">
                            <table class="table table-hover table-bordered table-preview table-custom">
                                <thead>
                                    <tr>
                                        <th width="40px">Chọn</th>
                                        <th width="100px">Tên Từ Vựng</th>
                                        <th>Nghĩa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="selectVocabularyPopupInfor.searchedList.length > 0">
                                        <tr v-for="(selectedItem, index) in selectVocabularyPopupInfor.searchedList">
                                            <td class="no-padding">
                                                <button class="btn btn-sm btn-primary full-width btn-add" type="button" @click="selectVocabulary(selectedItem, index)"><font-awesome-icon icon="arrow-down" /></button>
                                            </td>
                                            <td class="refer-item text-left" refer_id="vocabulary_nm">
                                                <v-popper arrow>
                                                    <span class="btn btn-sm btn-link">
                                                        {{ selectedItem.vocabularyName }}
                                                    </span>
                                                    <template #content>
                                                        <div class="text-left change-preview">
                                                            <div v-if="selectedItem.vocabularyName != ''">Từ Tiếng Anh: {{ selectedItem.vocabularyName }}</div>
                                                            <div v-if="selectedItem.vocabularyDivName != ''">Loại Từ: {{ selectedItem.vocabularyDivName }}</div>
                                                            <div v-if="selectedItem.vocabularySpecializedName != ''">Chuyên Ngành: {{ selectedItem.vocabularySpecializedName }}</div>
                                                            <div v-if="selectedItem.vocabularyFieldName != ''">Lĩnh Vực: {{ selectedItem.vocabularyFieldName }}</div>
                                                            <div v-if="selectedItem.vocabularySpelling != ''">Phiên Âm: {{ selectedItem.vocabularySpelling }}</div>
                                                            <div v-if="selectedItem.vocabularyMean != ''">Nghĩa: {{ selectedItem.vocabularyMean }}</div>
                                                            <div v-if="selectedItem.vocabularyImage != ''">
                                                                Hình Ảnh:<br />
                                                                <img :src="appInfor.domain + selectedItem.vocabularyImage" alt="oldVocabularyImage" />
                                                            </div>
                                                            <div v-if="selectedItem.vocabularyAudio != ''">
                                                                Âm Thanh:<br />
                                                                <audio :src="appInfor.domain + selectedItem.vocabularyAudio" controls />
                                                            </div>
                                                        </div>
                                                    </template>
                                                </v-popper>
                                            </td>
                                            <td class="refer-item text-left" refer_id="mean">{{ selectedItem.vocabularyMean }}</td>
                                        </tr>
                                    </template>
                                    <template v-else-if="selectVocabularyPopupInfor.paging.totalRecord == 0">
                                        <tr>
                                            <td colspan="11">Không có dữ liệu nào khớp với điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="11">Xin nhập điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <ClientOnly>
                            <Paging v-if="selectVocabularyPopupInfor.paging.totalRecord > 0" class="margin-bottom" :pagingId="selectVocabularyPopupInfor.paging.pagingCode" :currentPage="selectVocabularyPopupInfor.paging.currentPage" :totalPage="selectVocabularyPopupInfor.paging.totalPage" />
                        </ClientOnly>
                    </div>
                    <div class="col-xs-12 dialog-padding no-fixed result-panel">
                        <h5>
                            <font-awesome-icon icon="list-check" />
                            Từ Vựng Đã Được Chọn
                        </h5>
                        <div v-if="!appInfor.isMobile" class="table-fixed-width no-padding-left" min-width="700px">
                            <table class="table table-hover table-bordered table-preview table-custom" style="min-width: 900px">
                                <thead>
                                    <tr>
                                        <th width="40px">Xóa</th>
                                        <th class="hidden"></th>
                                        <th width="100px">Tên Từ Vựng</th>
                                        <th width="110px">Chuyên Ngành</th>
                                        <th width="120px">Lĩnh Vực</th>
                                        <th width="120px">Loại Từ Vựng</th>
                                        <th width="120px">Phiên Âm</th>
                                        <th>Nghĩa</th>
                                        <th width="90px">Hình Ảnh</th>
                                        <th width="90px">Âm Thanh</th>
                                        <th class="hidden"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="selectVocabularyPopupInfor.selectedList.length != 0">
                                        <tr v-for="(selectedItem, index) in selectVocabularyPopupInfor.selectedList">
                                            <td class="no-padding">
                                                <button v-if="selectedItem.isCanEdit" class="btn btn-sm btn-danger full-width btn-add" type="button" @click="unselectVocabulary(selectedItem, index)"><font-awesome-icon icon="close" /></button>
                                            </td>
                                            <td class="refer-item text-left" refer_id="vocabulary_nm">{{ selectedItem.vocabularyName }}</td>
                                            <td>{{ selectedItem.vocabularySpecializedName }}</td>
                                            <td>{{ selectedItem.vocabularyFieldName }}</td>
                                            <td>{{ selectedItem.vocabularyDivName }}</td>
                                            <td class="refer-item" refer_id="spelling">{{ selectedItem.vocabularySpelling }}</td>
                                            <td class="refer-item text-left" refer_id="mean">{{ selectedItem.vocabularyMean }}</td>
                                            <td class="refer-item" refer_id="image"></td>
                                            <td class="refer-item" refer_id="audio"><audio class="sound1" :src="appInfor.domain + selectedItem.vocabularyAudio"></audio><a type="button" class="preview-audio">Nghe thử</a></td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="11">Chưa có từ vựng nào được chọn</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div v-else class="table-fixed-width no-padding-left">
                            <table class="table table-hover table-bordered table-preview table-custom">
                                <thead>
                                    <tr>
                                        <th width="40px">Chọn</th>
                                        <th width="100px">Tên Từ Vựng</th>
                                        <th>Nghĩa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="selectVocabularyPopupInfor.selectedList.length != 0">
                                        <tr v-for="(selectedItem, index) in selectVocabularyPopupInfor.selectedList">
                                            <td class="no-padding">
                                                <button v-if="selectedItem.isCanEdit" class="btn btn-sm btn-danger full-width btn-add" type="button" @click="unselectVocabulary(selectedItem, index)"><font-awesome-icon icon="close" /></button>
                                            </td>
                                            <td class="refer-item text-left" refer_id="vocabulary_nm">{{ selectedItem.vocabularyName }}</td>
                                            <td class="refer-item text-left" refer_id="mean">{{ selectedItem.vocabularyMean }}</td>
                                        </tr>
                                    </template>
                                    <template v-else-if="selectVocabularyPopupInfor.paging.totalRecord == 0">
                                        <tr>
                                            <td colspan="11">Không có dữ liệu nào khớp với điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="11">Xin nhập điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger btn-sm float-left" id="btn-do-mission" type="button" @click="saveVocabularyList()">
                        <font-awesome-icon icon="download" />
                        Lưu Đóng Góp
                    </button>
                    <button class="btn btn-primary btn-sm" id="btn-do-mission" type="button" @click="getVocabularyList()">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                        Tim Kiếm
                    </button>
                    <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="selectVocabularyPopupInfor.showModal = false">
                        <font-awesome-icon icon="close" />
                        Đóng
                    </button>
                </div>
            </div>
            <ClientOnly>
                <v-loading :active="selectVocabularyPopupInfor.isSearching" :is-full-page="false">
                    <slot name="default">
                        <font-awesome-icon :icon="['fas', 'compact-disc']" spin size="3x" style="color: #777" />
                    </slot>
                </v-loading>
            </ClientOnly>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { selectVocabularyPopupStore } from "./store";
import { vocabularyStore } from "~~/pages/vocabulary/store.js";

/**
 * Define main layout
 */
const SelectVocabularyPopup = {
    name: "SelectVocabularyPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("selectVocabularyPopup")) {
        // 	store.registerModule("selectVocabularyPopup", selectVocabularyPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(selectVocabularyPopupStore, ["selectVocabularyPopupInfor"]),
    },
    data() {
        // var selectVocabularyPopup = selectVocabularyPopupStore();
        // return {
        //     unitPerTimes: selectVocabularyPopup.selectVocabularyPopupInfor.unitPerTimes,
        //     difficultLevel: selectVocabularyPopup.selectVocabularyPopupInfor.difficultLevel,
        //     successMissionExp: selectVocabularyPopup.selectVocabularyPopupInfor.successMissionExp,
        //     successMissionCtp: selectVocabularyPopup.selectVocabularyPopupInfor.successMissionCtp,
        //     failedMissionExp: selectVocabularyPopup.selectVocabularyPopupInfor.failedMissionExp,
        //     failedMissionCtp: selectVocabularyPopup.selectVocabularyPopupInfor.failedMissionCtp,
        // };
    },
    mounted() {
        this.$watch(
            () => ({
                showModal: this.selectVocabularyPopupInfor.showModal,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                if (newValue.showModal) {
                    var _this = this;
                    setTimeout(() => {
                        _this.$refs.vocabularyName.focus();
                    }, 500);
                }
            }
        );
    },
    methods: {
        ...mapActions(selectVocabularyPopupStore, ["showSelectVocabularyPopup", "updateSelectVocabularyPopupInfor", "getVocabularyList", "saveVocabularyList"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        updatePagingContent(paging, page) {
            if (paging.pagingId == 999) {
                //update paging content
                this.getVocabularyList(page);
            }
        },
        updateResizedScreen() {
            // this.updateSelectVocabularyPopupInfor({
            // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
            // });
        },
        selectVocabulary(vocabulary, index) {
            this.selectVocabularyPopupInfor.searchedList.splice(index, 1);
            this.selectVocabularyPopupInfor.selectedList.unshift(vocabulary);
        },
        unselectVocabulary(vocabulary, index) {
            this.selectVocabularyPopupInfor.selectedList.splice(index, 1);
            this.selectVocabularyPopupInfor.searchedList.push(vocabulary);
        },
    },
};
export default defineNuxtComponent(SelectVocabularyPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
