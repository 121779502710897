// import repository from '@/utils/repository';

export default {
    // getAll: (condition) => {
    //     return repository.get(`${resource}`, {
    //         params: condition
    //     });
    // },
    // getHomePageInfor: (data) => {
    //     return repository.post(`${resource}/get-homepage-infor`, data);
    // },
    login: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `/login`,
            data: data,
            showProgress: true,
        });
        // return repository.post(`/login`, {
        //     params: data
        // });
    },
    // logout: (data) => {
    //     return repository.post(`/logout`, {
    //         params: data
    //     });
    // },
    // getNewsList: (data) => {
    //     return repository.post(`${resource}/get-news-list`, {
    //         params: data
    //     });
    // },
    // delete: (code) => {
    //     return repository.delete(`${resource}/${code}`);
    // }
};
