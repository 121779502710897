<script setup>
import { appStore } from "@/store";
import { headerStore } from "~~/components/header/store";
import { footerStore } from "~~/components/footer/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { menuStore } from "~~/components/menu/store";
var app = appStore();
var header = headerStore();
var footer = footerStore();
var leftTab = leftTabStore();
var menu = menuStore();
var route = useRoute();
var user = useCookie("eplus365user").value;
const { isMobile } = useDevice();
app.appInfor.isMobile = isMobile;
const config = useRuntimeConfig();
const domain = process.dev ? config.public.VUE_APP_BASE_DOMAIN_CLIENT : config.public.VUE_APP_BASE_DOMAIN;
app.appInfor.domain = domain;
if (user != undefined && user.account_id != "") {
    app.appInfor.isLogined = true;
    menu.menuInfor.userName = user.account_nm;
    menu.menuInfor.avarta = user.userAvarta;
} else {
    app.appInfor.isLogined = false;
}
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `common/app-state`,
        data: { screen_div: route.meta.rootPath },
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    var data = res.data.data;
    // console.log(data);
    app.updateAppInfor({
        userPermission: data[0][0],
    });
    header.headerInfor = data[6][0];
    footer.footerInfor = data[7][0];
    footer.updateFooterInfor({
        contacts: data[8],
        epluser: data[5][0]["epluser"],
    });
    leftTab.updateLeftTabInfor({
        missionLists: data[4],
    });
    leftTab.updateQuestionLists({
        //use slice to copy array
        question: data[9].slice(),
        answer: data[10].slice(),
    });
    leftTab.updateLeftTabInfor({
        remainQuestionIdList: data[9].slice(),
        goldenBoardLists: data[2],
        myNotifyLists: data[1],
        systemNotifyLists: data[3],
    });
    leftTab.initQuestion();
    if (app.appInfor.isMobile) {
        leftTab.leftTabInfor.isCollapsed = true;
    } else {
        leftTab.leftTabInfor.isCollapsed = false;
    }
}
app.appInfor.isLoading = false;
</script>
<template>
    <!-- <ClientOnly > -->
    <KeepAlive>
        <div class="app">
            <Header />
            <Footer />
            <ClientOnly>
                <vue-progress-bar />
                <header-error-bar />
                <fixed-bottom-bar />
                <PopupDialog />
                <popup-select-vocabulary />
                <popup-vocabulary-quiz />
                <!-- <vue-editor /> -->
            </ClientOnly>
        </div>
    </KeepAlive>
    <!-- </ClientOnly> -->
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import Menu from '@/components/menu';

/**
 * import template
 */
// import template from './template.html';
import { appStore } from "@/store";
// import { appStore } from './store';

/**
 * Define main layout
 */
const App = {
    name: "App",
    // template: template,
    components: {
        // Menu,
    },
    async asyncData() {
        // import { appStore } from "@/store";
        // var app = appStore();
        // var heaer = headerStore();
        // const { $repository } = useNuxtApp();
        // var data = await $repository({
        //     method: "post",
        //     url: `common/app-state`,
        //     data: "",
        // });
        // // console.log(data);
        // header.updateHeaderInfor(data.data.data[6][0]);
        // app.appInfor.isLoading = false;
        // return 0;
        // const slogan = meta.title;
        // menu.updateMenuInfor({
        //     isLoading: false,
        //     userName: slogan,
        // });
    },
    mounted() {
        // var app = appStore();
        // this.getAppState();
        // if (!store.hasModule('header')) {
        //     store.registerModule('header', headerStore);
        // }
    },
    computed: {
        // ...mapState(headerStore, [
        //     'headerInfor',
        // ]),
    },
    methods: {
        // updateResizedScreen() {
        //     if (window.innerWidth > 768) {
        //         this.updateAppInfor({
        //             isMobile: false,
        //         });
        //     } else {
        //         this.updateAppInfor({
        //             isMobile: true,
        //         });
        //     }
        // },
    },
};
export default defineNuxtComponent(App);
</script>
<style lang="scss">
// @import "scss/common/screencontroller.scss";
</style>
