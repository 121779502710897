<template>
	<div v-if="headerErrorBarInfor.isShowHeaderErrorBar" class="col-xs-12 no-padding header-error-bar">
		<table class="full-width">
			<tbody>
				<tr>
					<td class="text-left" width="10px"></td>
					<td class="text-center">
						<h4 class="text-center">{{headerErrorBarInfor.errorMessage}}</h4>
					</td>
					<td class="text-right" width="10px">
						<button class="btn btn-sm btn-default" type="button" @click="headerErrorBarInfor.isShowHeaderErrorBar = false"><i class="glyphicon glyphicon-remove"></i></button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import HeaderErrorBarMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { headerErrorBarStore } from "./store";

/**
 * Define headerErrorBar layout
 */
const HeaderErrorBar = {
	name: "HeaderErrorBar",
	// template: template,
	components: {
		// HeaderErrorBarMenu
	},
	beforeCreate() {
		
	},
	// props: ["questionResource","isHaveHint","isHaveExplan"],
	computed: {
		...mapState(headerErrorBarStore, ["headerErrorBarInfor"]),
	},
	mounted() {
		this.$watch(
			() => ({
				isShowHeaderErrorBar: this.headerErrorBarInfor.isShowHeaderErrorBar,
			}),
			(newValue) => {
				var _this = this;
				if(newValue.isShowHeaderErrorBar){
					setTimeout(() => {
						_this.updateHeaderErrorBarInfor({
							isShowHeaderErrorBar: false,
						});
					}, 5000);
				}
			}
		);
	},
	methods: {
		...mapActions(headerErrorBarStore, ["updateHeaderErrorBarInfor"]),
	},
};
export default defineNuxtComponent(HeaderErrorBar);
</script>
<style lang="scss">
@import "./style.scss";
</style>

