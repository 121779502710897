<template>
    <div class="col-xs-12 no-padding bonds" id="original" style="display: block"></div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import FieldsLinkerMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { fieldsLinkerStore } from "./store";

/**
 * Define fieldsLinker layout
 */
const FieldsLinker = {
    name: "FieldsLinker",
    // template: template,
    components: {
        // FieldsLinkerMenu
    },
    beforeCreate() {},
    props: ["showList", "existingLinks"],
    computed: {
        ...mapState(fieldsLinkerStore, ["fieldsLinkerInfor"]),
    },
    mounted() {
        this.$watch(
            () => ({
                showList: this.showList,
                // existingLinks: this.existingLinks,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                this.updateFieldsLinkerInfor({
                    showList: newValue.showList,
                });
                var wordList = newValue.showList.map((a) => a.vocabularyName);
                var meanList = newValue.showList.map((a) => a.vocabularyMean);
                this.createFieldsLinker(
                    this.callHelpers("shuffleArray",[wordList]),
                    this.callHelpers("shuffleArray",[meanList]),
                    this.existingLinks
                );
            },
            {
                immediate: true,
            }
        );
    },
    methods: {
        ...mapActions(fieldsLinkerStore, ["updateFieldsLinkerInfor","checkFieldsLinkerAnswer"]),
        updateWhenScroll() {
            // Get the current scroll position
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
            if (currentScrollPosition < 0) {
                return;
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
                return;
            }
            // Here we determine whether we need to show or hide the navbar
            this.updateFieldsLinkerInfor({
                isShowBottomBar: false,
                lastScrollPosition: currentScrollPosition,
            });
            // setTimeout(() => {
            //     this.updateFieldsLinkerInfor({
            //         isShowBottomBar:false
            //     });
            // }, 2000);
            // Set the current scroll position as the last scroll position
        },
        createFieldsLinker(leftList, rightList, existingLinks) {
            var fieldLinks;
            var inputOri;
            inputOri = {
                localization: {},
                options: {
                    associationMode: "oneToOne", // oneToOne,manyToMany
                    lineStyle: "square-ends",
                    buttonErase: "Nối Lại Từ Đầu",
                    displayMode: "original",
                    whiteSpace: "normal", //normal,nowrap,pre,pre-wrap,pre-line,break-spaces default => nowrap
                    mobileClickIt: true,
                },
                Lists: [
                    {
                        name: "Từ Vựng",
                        list: leftList,
                    },
                    {
                        name: "Nghĩa",
                        list: rightList,
                        // mandatories: ["last_name", "email_adress"],
                    },
                ],
                existingLinks: existingLinks,
            };

            // $(".fieldLinkerSave").on("click", function () {
            //     var results = fieldLinks.fieldsLinker("getLinks");
            //     $("#output").html("output => " + JSON.stringify(results));
            // });
            $("#original").html = "";
            fieldLinks = $("#original").fieldsLinker("init", inputOri);
            this.updateFieldsLinkerInfor({
                fieldsLinker: fieldLinks,
            });
        },
    },
};
export default defineNuxtComponent(FieldsLinker);
</script>
<style lang="scss">
@import "./style.scss";
</style>
