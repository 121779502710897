<template>
    <vue-final-modal v-model="vocabularyQuizPopupInfor.showModal" :transition="vocabularyQuizPopupInfor.modalTransition" :clickToClose="false">
        <div class="modal-dialog select-vocabulary">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{appInfor.isMissionMode?'NHIỆM VỤ':'THỬ THÁCH'}} TỪ VỰNG</h5>
                    <!-- <button class="close" data-dismiss="modal" type="button" @click="vocabularyQuizPopupInfor.showModal = false">×</button> -->
                </div>
                <div v-if="vocabularyQuizPopupInfor.isLoading" class="modal-body">
                    <content-loader viewBox="0 0 400 200" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                        <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="15" rx="3" ry="3" width="100%" height="100" />
                        <rect x="0" y="120" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="135" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="150" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="180" rx="3" ry="3" width="100%" height="15" />
                    </content-loader>
                </div>
                <div v-if="!vocabularyQuizPopupInfor.isLoading" class="modal-body no-padding">
                    <div v-if="vocabularyQuizPopupInfor.quizMode == 0" class="col-xs-12 dialog-padding">
                        <label>Thử thách nối chữ ({{ vocabularyQuizPopupInfor.quizLevel + 1 }}/{{ vocabularyQuizPopupInfor.quizTotalLevel + 1 }})</label>
                        <span class="block">Nối từ tiếng anh ở cột trái với nghĩa tương ứng của từ đó ở cột phải</span>
                        <fields-linker :showList="vocabularyQuizPopupInfor.showList"></fields-linker>
                    </div>
                    <div v-if="vocabularyQuizPopupInfor.quizMode == 1" class="col-xs-12 dialog-padding">
                        <label>Thử thách điền chữ ({{ vocabularyQuizPopupInfor.quizLevel + 1 }}/{{ vocabularyQuizPopupInfor.quizTotalLevel + 1 }})</label>
                        <span class="block">Điền từ tiếng anh có nghĩa như sau bằng những chữ cái được cho</span>
                        <fill-text :showList="vocabularyQuizPopupInfor.showList" :quizLevel="vocabularyQuizPopupInfor.quizLevel"></fill-text>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button class="btn btn-danger btn-sm float-left" id="btn-do-mission" type="button" @click="saveVocabularyList()">
                        <font-awesome-icon icon="download" />
                        Lưu Đóng Góp
                    </button> -->
                    <button class="btn btn-primary btn-sm" id="btn-do-mission" type="button" @click="checkAnswer()">
                        <font-awesome-icon icon="circle-check" />
                        Kiểm Tra Kết Quả
                    </button>
                    <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="vocabularyQuizPopupInfor.showModal = false">
                        <font-awesome-icon icon="close" />
                        Đóng
                    </button>
                </div>
            </div>
            <ClientOnly>
                <v-loading :active="vocabularyQuizPopupInfor.isSearching" :is-full-page="false">
                    <slot name="default">
                        <font-awesome-icon :icon="['fas', 'compact-disc']" spin size="3x" style="color: #777" />
                    </slot>
                </v-loading>
            </ClientOnly>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { vocabularyQuizPopupStore } from "./store";

/**
 * Define main layout
 */
const VocabularyQuizPopup = {
    name: "VocabularyQuizPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("vocabularyQuizPopup")) {
        // 	store.registerModule("vocabularyQuizPopup", vocabularyQuizPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(vocabularyQuizPopupStore, ["vocabularyQuizPopupInfor"]),
    },
    data() {
        // var vocabularyQuizPopup = vocabularyQuizPopupStore();
        // return {
        //     unitPerTimes: vocabularyQuizPopup.vocabularyQuizPopupInfor.unitPerTimes,
        //     difficultLevel: vocabularyQuizPopup.vocabularyQuizPopupInfor.difficultLevel,
        //     successMissionExp: vocabularyQuizPopup.vocabularyQuizPopupInfor.successMissionExp,
        //     successMissionCtp: vocabularyQuizPopup.vocabularyQuizPopupInfor.successMissionCtp,
        //     failedMissionExp: vocabularyQuizPopup.vocabularyQuizPopupInfor.failedMissionExp,
        //     failedMissionCtp: vocabularyQuizPopup.vocabularyQuizPopupInfor.failedMissionCtp,
        // };
    },
    mounted() {
        // new LeaderLine(document.getElementById("btn1"), document.getElementById("btn2"));
        // const vocabularyName = ref(null);
        // console.log(this.$refs.abc);
        // this.$refs.abc.focus();
    },
    methods: {
        ...mapActions(vocabularyQuizPopupStore, ["showVocabularyQuizPopup", "updateVocabularyQuizPopupInfor", "checkAnswer", "getShowList"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        updateResizedScreen() {
            // this.updateVocabularyQuizPopupInfor({
            // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
            // });
        },
    },
};
export default defineNuxtComponent(VocabularyQuizPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
