<template>
	<div v-if="fixedBottomBarInfor.isShowBottomBar" class="col-xs-12 bottom-bar">
		<table class="full-width">
			<tbody>
				<tr>
					<td class="text-left">
						<h3 class="noselect" @click="previousRightTabItem()"><i class="glyphicon glyphicon-fast-backward"></i></h3>
					</td>
					<td class="text-center main" width="40%">
						<h3 @click="backToTop()"><i class="glyphicon glyphicon-eject"></i></h3>
					</td>
					<td class="text-right">
						<h3 class="noselect" @click="nextRightTabItem()"><i class="glyphicon glyphicon-fast-forward"></i></h3>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import FixedBottomBarMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { fixedBottomBarStore } from "./store";

/**
 * Define fixedBottomBar layout
 */
const FixedBottomBar = {
	name: "FixedBottomBar",
	// template: template,
	components: {
		// FixedBottomBarMenu
	},
	beforeCreate() {
		
	},
	// props: ["questionResource","isHaveHint","isHaveExplan"],
	computed: {
		...mapState(fixedBottomBarStore, ["fixedBottomBarInfor"]),
	},
	mounted() {},
	methods: {
		...mapActions(fixedBottomBarStore, ["updateFixedBottomBarInfor"]),
		updateWhenScroll() {
			// Get the current scroll position
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			// Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
			if (currentScrollPosition < 0) {
				return;
			}
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
                return
            }
			// Here we determine whether we need to show or hide the navbar
			this.updateFixedBottomBarInfor({
				isShowBottomBar: false,
				lastScrollPosition: currentScrollPosition,
			});
            // setTimeout(() => {
            //     this.updateFixedBottomBarInfor({
            //         isShowBottomBar:false
            //     });
            // }, 2000);
			// Set the current scroll position as the last scroll position
		},
	},
};
export default defineNuxtComponent(FixedBottomBar);
</script>
<style lang="scss">
@import "./style.scss";
</style>

