<template>
	<vue-final-modal v-model="loginPopupInfor.showModal" :transition="loginPopupInfor.modalTransition">
		<div class="modal-dialog">
			<!-- Modal content-->
			<div class="modal-content">
				<div class="modal-header login-modal">
					<button class="close" data-dismiss="modal" type="button" @click="loginPopupInfor.showModal = false">×</button>
					<div class="modal-title login-modal">
						<span>ĐĂNG NHẬP E+</span>
					</div>
					<div class="login-with">
						<span class="btn-link">
							<font-awesome-icon icon="fa-brands fa-square-facebook" title="Đăng nhập với facebook" />
							Đăng nhập
						</span>
					</div>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label> Tên Đăng Nhập </label>
						<div class="input-group" v-tooltip.tooltip="loginPopupInfor.username.isError?loginPopupInfor.username.errorMessage:''">
							<input class="form-control input-sm" :class="loginPopupInfor.username.isError?'input-error':''" id="account_nm" type="type" v-model="loginPopupInfor.username.value" />
						</div>
					</div>
					<div class="form-group">
						<label> Mật Khẩu </label>
						<div class="input-group" v-tooltip.tooltip="loginPopupInfor.password.isError?loginPopupInfor.password.errorMessage:''">
							<input class="form-control input-sm" :class="loginPopupInfor.password.isError?'input-error':''" name="" type="password" id="password" v-model="loginPopupInfor.password.value"/>
						</div>
					</div>
					<label class="checkbox-inline"><input type="checkbox" tabindex="3" id="remember" maxlength="" v-model="loginPopupInfor.remember"/>Lưu mật khẩu đăng nhập</label>
					<span v-if="loginPopupInfor.loginMessage!=''" class="login-message">{{loginPopupInfor.loginMessage}}</span>
				</div>
				<div class="modal-footer">
					<NuxtLink to="/register" class="btn btn-danger btn-sm float-left" @click="loginPopupInfor.showModal = false">
						Tạo Tài Khoản
					</NuxtLink>
					<button class="btn btn-primary btn-sm" id="btn_login" type="button" @click="login()">Đăng Nhập</button>
					<button class="btn btn-default btn-sm" type="button" @click="loginPopupInfor.showModal = false">Hủy</button>
				</div>
			</div>
		</div>
	</vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store.js";
import { loginPopupStore } from "./store";

/**
 * Define main layout
 */
const LoginPopup = {
	name: "LoginPopup",
	// template: template,
	components: {
		// MainMenu,
	},
	beforeCreate() {
		// if (!store.hasModule("loginPopup")) {
		// 	store.registerModule("loginPopup", loginPopupStore);
		// }
	},
	created() {
		const userInfor = JSON.parse(localStorage.getItem("userInfor"));
        const password = localStorage.getItem('password');
        const remember = localStorage.getItem('remember');
        if (remember && userInfor && userInfor.account_id !='') {
            this.updateLoginPopupInfor({
				formInput:{
					username: userInfor.account_nm,
					password: atob(password),
					remember: remember,
				}
			});
        }else{
			this.updateLoginPopupInfor({
				formInput:{
					username: '',
					password: '',
					remember: false,
				}
			});
		}
	},
	computed: {
		...mapState(loginPopupStore, ["loginPopupInfor"]),
	},
	mounted() {
		// console.log(this.callHelpers('getMessage','E003'));
		// this.getLoginPopupInfor();
	},
	methods: {
		...mapActions(loginPopupStore, [
			"login",
			"updateLoginPopupInfor",
		]),
		confirm() {
			// some code...
			this.show = false;
		},
		cancel(close) {
			// some code...
			close();
		},
		updateResizedScreen() {
			// this.updateLoginPopupInfor({
			// 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
			// });
		},
	},
};
export default defineNuxtComponent(LoginPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
