<template>
    <div class="row top-header">
        <nav class="navbar-default">
            <div class="container-fluid1" ref="menuContainerFluid">
                <ul class="nav navbar-nav hover-item mobile-show">
                    <li v-if="appInfor.isLogined" class="dropdown-toggle mobile-show brand" id="menu1" data-toggle="dropdown">
                        <img :src="appInfor.domain + menuInfor.avarta" height="30" style="display: inline-block; border: 2px solid #eee" />
                        <span>
                            <span style="margin: 0px 5px">{{ menuInfor.userName }}</span>
                            <font-awesome-icon icon="angles-down" size="xs" />
                        </span>
                    </li>
                    <ul v-if="appInfor.isLogined" class="dropdown-menu user-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="/master">Trang Quản Trị</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="/profile">Trang Cá Nhân</a></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#">JavaScript</a></li>
                        <li role="presentation" class="divider"></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" @click="logout()">Đăng Xuất</a></li>
                    </ul>
                    <li v-if="!appInfor.isLogined" class="btn-open-login btn-popup brand mobile-show" @click="loginPopupInfor.showModal = true">
                        <font-awesome-icon icon="right-to-bracket" size="md" />
                        <span> ĐĂNG NHẬP / ĐĂNG KÝ</span>
                    </li>
                    <li v-if="appInfor.isMissionMode && $route.matched.some(({ meta }) => meta.rootPath === appInfor.doingMission.missionLink)" class="dropdown-toggle mobile-show brand" @click="cancelMission()">
                        <span class="btn-cancel-mission">
                            <font-awesome-icon icon="ban" size="md" />
                            <span> Hủy Nhiệm Vụ</span>
                        </span>
                    </li>
                    <li class="btn menu-btn mobile-show" data-target="#menu" data-toggle="collapse">
                        <font-awesome-icon icon="bars" />
                    </li>
                </ul>
                <ul class="nav navbar-nav collapse" :class="menuInfor.menuClassToggle" id="menu">
                    <li v-for="menu in menuInfor.menuList" :class="$route.matched.some(({ meta }) => meta.rootPath === menu.menuLink) ? 'active-menu' : ''" :style="{ width: menuInfor.menuItemWidth }">
                        <NuxtLink :to="menu.menuLink">{{ menu.menuName }}</NuxtLink>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <ClientOnly>
        <PopupLogin />
    </ClientOnly>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
import { loginPopupStore } from "@/components/popup/login/store";

/**
 * import template
 */
// import template from "./template.html";
import { appStore } from "@/store";
import { menuStore } from "./store";

const app = appStore();
/**
 * Define main layout
 */
const Menu = {
    name: "Menu",
    // template: template,
    components: {
        // LoginPopup,
        // MainMenu
    },
    beforeCreate() {
        // if (!store.hasModule("menu")) {
        // 	store.registerModule("menu", menuStore);
        // }
    },
    mounted() {
    },
    computed: {
        ...mapState(menuStore, ["menuInfor"]),
        ...mapState(loginPopupStore, ["loginPopupInfor"]),
    },
    methods: {
        ...mapActions(menuStore, ["updateMenuInfor","cancelMission"]),
        updateResizedScreen() {
            if (window.innerWidth < 680) {
                var temp = Math.floor(this.$refs.menuContainerFluid.clientWidth / 124);
                this.updateMenuInfor({
                    menuItemWidth: Math.floor(this.$refs.menuContainerFluid.clientWidth / temp) + "px",
                });
            } else {
                this.updateMenuInfor({
                    menuItemWidth: "auto",
                });
            }

            if (window.innerWidth > 1000) {
                this.updateMenuInfor({ menuClassToggle: "in" });
            } else {
                this.updateMenuInfor({ menuClassToggle: "" });
            }
        },
        logout() {
            const eplus365token = useCookie("eplus365token");
            eplus365token.value = null;
            const eplus365user = useCookie("eplus365user");
            eplus365user.value = null;
            this.updateAppInfor({
                isLogined: false,
            });
            this.updateMenuInfor({
                userName: "",
                avarta: "",
            });
            app.getAppState();
            // window.location.reload();
            // app.resetAppState();
        },
        closeDialog(){
            this.dialogPopupInfor.showDialog = false;
        }
    },
};
export default defineNuxtComponent(Menu);
</script>
<style lang="scss">
@import "./style.scss";
</style>
