<template>
    <div class="col-xs-12 no-padding vocabulary-box margin-top" target-id="">
        <div class="col-xs-12 no-padding mean-box">
            <span class="input-title-box sub-text">Nghĩa</span>
            <h2 class="form-control input-sm" disabled="">{{ fillTextInfor.showTextmean }}</h2>
        </div>
        <div class="col-xs-12 no-padding word-box">
            <span class="input-title-box sub-text">Từ Tiếng Anh</span>
            <div class="div-check">
                <input class="form-control input-sm text-center margin-bottom" ref="vocabularyName" :disabled="appInfor.isMobile" v-model="fillTextInfor.filledText" @keyup.enter="checkAnswer()"/>
                <font-awesome-icon v-if="fillTextInfor.isCorrect === true" icon="fa-solid fa-check" class="input-check text-success" />
                <font-awesome-icon v-if="fillTextInfor.isCorrect === false" icon="fa-solid fa-close" class="input-check text-danger" />
            </div>
        </div>
        <div class="col-xs-12 no-padding input-box margin-bottom">
            <div v-for="charracter in fillTextInfor.showCharracters" class="col-xs-2 no-padding">
                <button class="btn btn-sm btn-default full-width" @click="charracter !== '_blank' && fillText(charracter)" :disabled="charracter === '_blank'" v-html="charracter"></button>
            </div>
            <div class="col-xs-12 no-padding">
                <div class="col-xs-4 no-padding">
                    <button class="btn btn-sm btn-default full-width" @click="fillText('clear')">
                        <font-awesome-icon :icon="['fas', 'eraser']" />
                    </button>
                </div>
                <div class="col-xs-4 no-padding">
                    <button class="btn btn-sm btn-default full-width" @click="fillText('space')">⌴</button>
                </div>
                <div class="col-xs-4 no-padding">
                    <button class="btn btn-sm btn-default full-width" @click="fillText('backspace')">
                        <font-awesome-icon :icon="['fas', 'delete-left']" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import FillTextMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { fillTextStore } from "./store";
import { vocabularyQuizPopupStore } from "~/components/popup/vocabulary-quiz/store.js";
/**
 * Define fillText layout
 */
const FillText = {
    name: "FillText",
    // template: template,
    components: {
        // FillTextMenu
    },
    beforeCreate() {},
    props: ["showList", "quizLevel"],
    computed: {
        ...mapState(fillTextStore, ["fillTextInfor"]),
    },
    mounted() {
        this.$watch(
            () => ({
                showList: this.showList,
                // quizLevel: this.quizLevel,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                this.updateFillTextInfor({
                    showList: this.callHelpers("shuffleArray", [newValue.showList]),
                });
                this.fillTextInfor.showTextmean = this.fillTextInfor.showList[this.quizLevel].vocabularyMean;
                this.fillTextInfor.showText = this.fillTextInfor.showList[this.quizLevel].vocabularyName;
                this.createFillText(this.fillTextInfor.showList[this.quizLevel].vocabularyName);
            },
            {
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                // showList: this.showList,
                quizLevel: this.quizLevel,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                // this.updateFillTextInfor({
                //     showList: this.callHelpers("shuffleArray",[newValue.showList]),
                // });
                this.fillTextInfor.showTextmean = this.fillTextInfor.showList[newValue.quizLevel].vocabularyMean;
                this.fillTextInfor.showText = this.fillTextInfor.showList[this.quizLevel].vocabularyName;
                this.createFillText(this.fillTextInfor.showList[newValue.quizLevel].vocabularyName);
            }
        );
        this.$watch(
            () => ({
                // showList: this.showList,
                filledText: this.fillTextInfor.filledText,
            }),
            (newValue) => {
                this.fillTextInfor.isCorrect = null;
            }
        );
        this.$watch(
            () => ({
                // showList: this.showList,
                isVocabularyQuizPopupOpen: vocabularyQuizPopupStore().vocabularyQuizPopupInfor.showModal,
                quizLevel: this.quizLevel,
            }),
            (newValue) => {
                if(!this.appInfor.isMobile && newValue.isVocabularyQuizPopupOpen){
                    var _this = this;
                    setTimeout(() => {
                        this.$refs.vocabularyName.focus();
                    }, 50);
                }
            },
            {
                immediate: true,
            }
        );
    },
    methods: {
        ...mapActions(fillTextStore, ["updateFillTextInfor", "checkFillTextAnswer"]),
        ...mapActions(vocabularyQuizPopupStore, ["checkAnswer"]),
        createFillText(currentText) {
            // const ALPHABET = "QWERTYUIOPASDFGHJKLZXCVBNM".split("");
            currentText = currentText.replace(/\s/g, "").toUpperCase();
            const uniqueArray = currentText.split("").filter((item, index) => currentText.indexOf(item) === index);
            // var notInArray = this.callHelpers("shuffleArray", [ALPHABET.filter((a) => uniqueArray.indexOf(a) === -1)]);
            // var showCharracters = uniqueArray.concat(notInArray.slice(0, 16 - uniqueArray.length));
            var charracterLength = 0;
            if (uniqueArray.length <= 6) {
                charracterLength = 1;
            } else {
                charracterLength = uniqueArray.length % 6 != 0 ? Math.floor(uniqueArray.length / 6) + 1 : Math.floor(uniqueArray.length / 6);
            }
            var showCharracters = this.callHelpers("shuffleArray", [uniqueArray]);
            while (showCharracters.length < charracterLength * 6) {
                showCharracters.push("_blank");
            }
            this.fillTextInfor.showCharracters = showCharracters;
            this.fillTextInfor.isCorrect = null;
            this.fillTextInfor.filledText = "";
        },
        fillText(charracter) {
            switch (charracter) {
                case "space":
                    this.fillTextInfor.filledText += " ";
                    break;
                case "backspace":
                    this.fillTextInfor.filledText = this.fillTextInfor.filledText.slice(0, this.fillTextInfor.filledText.length - 1);
                    break;
                case "clear":
                    this.fillTextInfor.filledText = "";
                    break;
                default:
                    this.fillTextInfor.filledText += charracter;
                    break;
            }
        },
    },
};
export default defineNuxtComponent(FillText);
</script>
<style lang="scss">
@import "./style.scss";
</style>
