import repository from "./repository";
import { appStore } from "@/store";
import { menuStore } from "@/components/menu/store.js";
export const loginPopupStore = defineStore('loginPopup', {
    namespaced: true,
    state: () => ({
        loginPopupInfor: {
            isLoading: true,
            showModal: false,
            modalTransition: 'fade',
            loginMessage: '',
            username: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            password: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            remember: '',
            rules: {
                'username.value': 'required',
                'password.value': 'required|min:8',
            }
        }
    }),
    mutations: {


    },
    actions: {
        updateLoginPopupInfor(loginPopupInfor) {
            var _this = this;
            Object.keys(loginPopupInfor).forEach(function (key) {
                _this.loginPopupInfor[key] = loginPopupInfor[key];
            })
        },
        login() {
            var state = this;
            const { $Validator,$callHelpers } = useNuxtApp();
            const app = appStore();
            const menu = menuStore();
            try {
                state.loginPopupInfor.loginMessage = '';
                var validator = $Validator.checkValidate(state.loginPopupInfor.rules, state.loginPopupInfor);
                if (validator) {
                    repository.login({
                        username: state.loginPopupInfor.username.value,
                        password: state.loginPopupInfor.password.value,
                        remember: state.loginPopupInfor.remember,
                    })
                        .then((res) => {
                            switch (res.status) {
                                case 200:
                                    var data = res.data;
                                    menu.updateMenuInfor({
                                        userName: data.userInfor.account_nm,
                                        avarta: data.userInfor.userAvarta,
                                    });
                                    // get instance
                                    const eplus365token = useCookie('eplus365token', {
                                        maxAge: data.userInfor.tokenTimeout
                                    })
                                    eplus365token.value = data.userInfor.token;
                                    const eplus365user = useCookie('eplus365user', {
                                        maxAge: data.userInfor.tokenTimeout
                                    })
                                    eplus365user.value = data.userInfor;
                                    const eplus365remember = useCookie('eplus365remember', {
                                        maxAge: data.userInfor.tokenTimeout
                                    })
                                    eplus365remember.value = state.loginPopupInfor.formInput.remember;
                                    state.loginPopupInfor.showModal = false;
                                    app.updateAppInfor({
                                        isLogined: true,
                                    });
                                    app.getAppState();
                                    // window.location.reload();
                                    break;
                                default:
                                    break;
                            }
                        })
                        .catch((res) => {
                            var resError = res.response;
                            switch (resError.status) {
                                case 511:
                                    $Validator.setValidateError(resError.data.errors, state.loginPopupInfor);
                                    break;
                                case 512:
                                    var timeleft = resError.data.seconds;
                                    state.loginPopupInfor.loginMessage = $callHelpers('getMessage', ['E003', [timeleft, '5']]);
                                    var downloadTimer = setInterval(function () {
                                        if (timeleft <= 0) {
                                            clearInterval(downloadTimer);
                                            state.loginPopupInfor.loginMessage = '';
                                        } else {
                                            state.loginPopupInfor.loginMessage = $callHelpers('getMessage', ['E003', [timeleft, '5']]);
                                        }
                                        timeleft -= 1;
                                    }, 1000);
                                    break;
                                case 513:
                                    var error = {
                                        username: $callHelpers('getMessage', 'E001'),
                                        password: $callHelpers('getMessage', 'E001'),
                                    }
                                    $Validator.setValidateError(error, state.loginPopupInfor);
                                    break;
                                case 514:
                                    state.loginPopupInfor.loginMessage = $callHelpers('getMessage', ['E002']);
                                    break;
                                case 515:
                                    var blockDate = resError.data.block_time;
                                    state.loginPopupInfor.loginMessage = $callHelpers('getMessage', ['E004', [blockDate, '5']]);
                                    break;
                                // case 500:
                                //     state.loginPopupInfor.loginMessage = $callHelpers('getMessage', ['E999']);
                                //     break;
                                default:
                                    break;
                            }
                        });
                }
            } catch (e) {
                console.log("Action login: " + e.message);
            }
        },
        getNewsList(context, page) {
            context.commit('getNewsList', page);
        },
    }
});